import { BookOpenIcon } from "@heroicons/react/24/outline";
import { useAccountGetAppropriatenessAssessment } from "@internal/rest/generated/queries/accounts";
import { StepIntro } from "screens/onboarding/components/StepIntro";

import { useInvestorAssessmentContext } from "../../AssessmentStepContext";

export function AssessmentOverview() {
  const { setActiveStep } = useInvestorAssessmentContext();

  useAccountGetAppropriatenessAssessment(); // prefetch assessment info (questions + status)

  return (
    <StepIntro
      title="Investor Assessment"
      icon={<BookOpenIcon width="2rem" color="white" />}
      desc={
        <>
          Almost there! Before you invest, we need to make sure you understand
          the risks of investing. We do this via a short assessment.
          <br />
          <br />
          If you answer too many questions incorrectly you will have to retake
          the assessment and may be blocked from investing.
        </>
      }
      onContinue={() => setActiveStep("questions")}
    />
  );
}
